
  import { Vue, Component, Watch } from "vue-property-decorator";
  import { dispatchSearchDirectory } from "@/store/main/actions";
  import { readDirectoryEntries } from "@/store/main/getters";
  import DirectoryEntry from "@/components/DirectoryEntry.vue";
  import { debounce } from "@/utils";

  const debouncedSearch = debounce(dispatchSearchDirectory, 500);
  const pageLimit = 36;

  @Component({
    components: {
      DirectoryEntry,
    },
  })
  export default class Directory extends Vue {
    query = "";
    country = "";
    province = "";
    town = "";
    bottom = false;
    loadingNextPage = false;
    searchFields: string[] = [];
    possibleSearchFields = [
      { name: "Country", value: "country" },
      { name: "State/Province", value: "province" },
      { name: "City/Town", value: "town" },
      { name: "Provider Name", value: "name" },
    ];

    get directoryEntries() {
      return readDirectoryEntries(this.$store);
    }

    get skip() {
      return this.directoryEntries.length;
    }

    get showCountry() {
      return this.searchFields.includes("country");
    }

    get showProvince() {
      return this.searchFields.includes("province");
    }

    get showTown() {
      return this.searchFields.includes("town");
    }

    get showName() {
      return this.searchFields.includes("name");
    }

    get countryQuery() {
      if (this.showCountry) {
        return this.country || null;
      }
      return null;
    }

    get provinceQuery() {
      if (this.showProvince) {
        return this.province || null;
      }
      return null;
    }

    get townQuery() {
      if (this.showTown) {
        return this.town || null;
      }
      return null;
    }

    get nameQuery() {
      if (this.showName) {
        return this.query || null;
      }
      return null;
    }

    public async mounted() {
      await dispatchSearchDirectory(this.$store, {
        query: this.nameQuery,
        country: this.countryQuery,
        province: this.provinceQuery,
        town: this.townQuery,
        limit: pageLimit,
        skip: 0,
      });
    }

    created() {
      window.addEventListener("scroll", () => {
        this.bottom = this.bottomVisible();
      });
    }

    bottomVisible() {
      const scrollY = window.scrollY;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage =
        visible + scrollY >= pageHeight - this.$vuetify.application.footer;
      return bottomOfPage || pageHeight < visible;
    }

    @Watch("searchFields")
    async onSearchFieldsChanged() {
      if (!this.showCountry && this.country) {
        this.country = "";
      }
      if (!this.showProvince && this.province) {
        this.province = "";
      }
      if (!this.showTown && this.town) {
        this.town = "";
      }
      if (!this.showName && this.query) {
        this.query = "";
      }
      await dispatchSearchDirectory(this.$store, {
        query: this.nameQuery,
        country: this.countryQuery,
        province: this.provinceQuery,
        town: this.townQuery,
        limit: pageLimit,
        skip: 0,
      });
    }

    @Watch("query")
    async onQueryChanged(newQuery: string) {
      await debouncedSearch(this.$store, {
        query: newQuery,
        country: this.countryQuery,
        province: this.provinceQuery,
        town: this.townQuery,
        limit: pageLimit,
        skip: 0,
      });
    }

    @Watch("country")
    async onCountryQueryChanged(newCountryQuery: string) {
      await debouncedSearch(this.$store, {
        query: this.nameQuery,
        country: newCountryQuery || null,
        province: this.provinceQuery,
        town: this.townQuery,
        limit: pageLimit,
        skip: 0,
      });
    }

    @Watch("province")
    async onProvinceQueryChanged(newProvinceQuery: string) {
      await debouncedSearch(this.$store, {
        query: this.nameQuery,
        country: this.countryQuery,
        province: newProvinceQuery || null,
        town: this.townQuery,
        limit: pageLimit,
        skip: 0,
      });
    }

    @Watch("town")
    async onTownQueryChanged(newTownQuery: string) {
      await debouncedSearch(this.$store, {
        query: this.nameQuery,
        country: this.countryQuery,
        province: this.provinceQuery,
        town: newTownQuery || null,
        limit: pageLimit,
        skip: 0,
      });
    }

    @Watch("bottom")
    async onBottomChanged(newBottom: boolean) {
      if (newBottom && this.skip % pageLimit === 0) {
        this.loadingNextPage = true;
        await dispatchSearchDirectory(this.$store, {
          query: this.nameQuery,
          country: this.countryQuery,
          province: this.provinceQuery,
          town: this.townQuery,
          limit: pageLimit,
          skip: this.skip,
        });
        this.loadingNextPage = false;
      }
    }
  }
