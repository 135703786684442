
  import { IDirectoryEntry } from "@/interfaces";
  import { Component, Vue, Prop } from "vue-property-decorator";

  @Component
  export default class DirectoryEntry extends Vue {
    @Prop({ required: true }) public directoryEntry!: IDirectoryEntry;

    get initials() {
      return this.directoryEntry.name
        ?.split(" ")
        .map(([firstLetter]) => firstLetter)
        .filter((_, index, array) => index === 0 || index === array.length - 1)
        .join("")
        .toUpperCase();
    }
  }
